import React from 'react';
import Footer from '../components/footer';
import Navigation from '../components/navigation';
import SEO from '../components/seo';
import { ToastContainer } from 'react-toastify';

const Contact = () => {
    return (
        <div id="page-wrapper">
            <SEO title="Contáctanos" />
            <Navigation />
            <ToastContainer/>
            .
            <Footer />
        </div>
    );
};

export default Contact;